@import 'helpers/variables'; // Imported first, to overwrite bootstrap defaults.
// Bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';

// Custom
@import 'helpers/mixins';

// Body
// Webfonts
@import url('https://fonts.googleapis.com/css?family=Graduate|Reenie+Beanie|Itim|Bungee|Lobster|Staatliches|Special+Elite|Playball|Iceland|Creepster|UnifrakturMaguntia|Kelly+Slab|Nova+Flat|Audiowide|Bebas+Neue|Bangers|Secular+One|Fira+Sans:300,400,700&display=swap');

body {
  font-size: .8rem;
  @include media-breakpoint-up(sm) {
    font-size: .9rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  -webkit-tap-highlight-color: transparent;
}

.tmdb-logo {
  width: 100px;
  border: none;

}

#root {
  position: relative;
  min-height: 100vh;
}

// Image helpers
img {
  border: 1px solid $color-black;
}

// Opacity helpers
.opacity-5 {
  opacity: 1 !important;
}

// Typography
p a {
  color: $color-secondary;

  &:hover {
    color: $color-secondary__lighten;
  }
}

strong {
  font-weight: bold;
}

// Responsive font sizes
h1 {
  margin-bottom: 1.5rem;
  font-size: $font-size-base * 1.2;
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 2;
  }
  @include media-breakpoint-up(xl) {
    font-size: $font-size-base * 2.2;
  }
}

h2 {
  font-size: $font-size-base * 1;
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 1.65;
  }
  @include media-breakpoint-up(xl) {
    font-size: $font-size-base * 2;
  }
}

h3 {
  font-size: $font-size-base * .8;
  @include media-breakpoint-up(sm) {
    font-size: $font-size-base * 1.3;
  }
}

h4, h5, h6 {
  font-size: $font-size-base * 1;
}

p:last-of-type, .lead {
  margin-bottom: 1.5rem;
}

label {
  font-weight: 700;
  font-size: $font-size-base * .875;
}

.text-truncate__200px {
  @extend .text-truncate;
  max-width: 200px;
}

// variants
.logo-font-family__4 {
  font-family: $tbf-font__logo-4;
}

.small {
  font-weight: 300;
  font-size: 85%;
}

.section-header {
  text-transform: capitalize;
  border-bottom: 1px solid $color-border;
  padding-bottom: .5rem;
  margin-bottom: 1rem;
}

.fs-lg {
  font-size: 120%;
}

.text-black {
  color: $color-dark-grey;
}

.font-family-regular {
  font-family: $tbf-font__regular;
}

// Cards
.card-title {
  color: $color-white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 80%;
}

.card-sub-title {
  font-size: 80%;
  text-transform: capitalize;
}

.hd-episode-card-wrapper {
  img {
    border: none;
  }
  .card-title {
    font-size: 1.8rem;
  }
  .card-sub-title {
    font-size: 100%;
  }
  .hd-episode-image-info {
    position: absolute;
    bottom: 0;
    right: 15px;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 8px;
    border-top-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    border-top-right-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topright: 5px;

    &.haller {
      background-color: darkgreen;
    }
    &.haller-inte {
      background-color: darkred;
    }
    &.haller-lika {
      background-color: orangered;
    }
  }
  .green-icon {
    color: darkgreen;
  }
  .red-icon {
    color: darkred;
  }
  .card {
    opacity: .8;
  }
  a {
    color: $color-white;
    &:hover {
      text-decoration: none;
    }
    .card:hover {
      opacity: 1;
      transition: $transition-base;
    }
  }
}
